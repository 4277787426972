/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import '@ionic/angular/css/palettes/dark.always.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
/* For responsiveness across screens */
html {
  font-size: 100%;
}
@media (max-width: 289px) {
  html {
    font-size: 45%;
  }
}
@media (min-width: 290px) {
  html {
    font-size: 50%;
  }
}
@media (min-width: 300px) {
  html {
    font-size: 52.5%;
  }
}
@media (min-width: 320px) {
  html {
    font-size: 55.5%;
  }
}
@media (min-width: 325px) {
  html {
    font-size: 56.5%;
  }
}
@media (min-width: 326px) {
  html {
    font-size: 57%;
  }
}
@media (min-width: 330px) {
  html {
    font-size: 59%;
  }
}
@media (min-width: 360px) {
  html {
    font-size: 62.5%;
  }
}
@media (min-width: 411px) {
  html {
    font-size: 70%;
  }
}
@media (min-width: 500px) {
  html {
    font-size: 85%;
  }
}
@media (min-width: 720px) {
  html {
    font-size: 100%;
  }
}
@media (min-width: 1000px) {
  html {
    font-size: 110%;
  }
}

@media (max-width: 359px) and (min-width: 320px) {
  html.ios {
    font-size: 59%;
  }
}